html, body {
  
  width: 100%;
  background-size: cover;
  overflow-x: hidden;

}

body {
  height: 100%;
  min-height: 100%;
}

.App {
  text-align: center;

}

.cursive {
  font-family: Georgia, 'Times New Roman', Times, serif;
  font-size: 32pt;
  font-weight: bold;
  
}

.times {
  font-family: Georgia, 'Times New Roman', Times, serif;
}



h1 {
  font-size: 52pt;
  margin: 0;
  font-family: Georgia, 'Times New Roman', Times, serif;
  letter-spacing: -3px;
}

.label {
  font-weight: bold;
  font-size: 18pt;
}

::placeholder {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
}

select {
  font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande', 'Lucida Sans', Arial, sans-serif;
  height: 30px;
}

input {
  height: 28px;
  border-width: 1px;
  border-radius: 3px;
}

.categories {
  border-width: 1px;
  border-radius: 3px;
}

.search-area {
  width: 100vw;
  height: 100px;
  display: flex;
  justify-content: space-evenly;
  align-items: baseline;
  margin: 2.5% 0% 2% 0%;
  
  
}

@media screen and (max-width: 1600px) {
  .search-area {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    height: 100%;
    width: 100vw;
  }

  .label {
    font-size: 10pt;
    width: 100%;
    margin-top: 10px;
    margin-bottom: 10px;
  }

  

  form {
    width: 100vw;
    display: flex;
    flex-direction: column;
  }
}

form {
  width: 850px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  
  


}



.photos {
  margin: 0% 5% 0% 5%;
  width:100%;
  height: auto;
  text-align: center;
  
  
}


.images-ul {
  padding: 0;
  margin: 0;
  list-style: none;
  text-align: center;
 
}

.images-ul ul {
width: 80%;

}

.images-ul li {
  position: relative;
  float: left;
  height: calc(40vh);
  width: calc(30% - 10px);
  overflow: hidden;
  margin: 5px;
  
}

.images-ul img {
position: absolute;
left: 50%;
top: 50%;
height: auto;
width: auto;

animation-name: slide;
animation-duration: 2000ms;
animation-timing-function: ease-in-out;
-webkit-transform: translate(-50%,-50%);
    -ms-transform: translate(-50%,-50%);
        transform: translate(-50%,-50%);
}

.images-ul img:hover {
  cursor: pointer;
}

.backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.5)
}

.backdrop img {
  display: block;
  max-width: 70%;
  max-height: 80%;
  margin: 60px auto;
  box-shadow: 3px 5px 7px rgba(0,0,0,0.5);
  border: 3px solid white;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.loader:empty {
  position: absolute;
  top: calc(50% - 4em);
  left: calc(50% - 4em);
  width: 6em;
  height: 6em;
  border: 1.1em solid rgba(0, 0, 0, 0.2);
  border-left: 1.1em solid #000000;
  border-radius: 50%;
  animation: load8 1.1s infinite linear;
  transition: opacity 0.3s;
}


@keyframes load8 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
